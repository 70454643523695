export const consolidateSanctionsData = (data, keys) => {
    const map = new Map();

    data.forEach((row: any) => {
        const uniqueKey = row[keys].trim();
        if (!map.has(uniqueKey)) {
            const newRow: any = {};
            Object.keys(row).forEach((col) => {
                if (col === "sanctionsDescription") {
                    newRow[col] = row[col] || "";
                } else if (col === "id") {
                    newRow[col] = new Set([row[col].trim()]);
                } else {
                    newRow[col] = new Set([row[col]]);
                }
            });
            map.set(uniqueKey, newRow);
        } else {
            const existingRow = map.get(uniqueKey);
            Object.keys(row).forEach((col) => {
                if (col === "sanctionsDescription") {
                    const existingDescription = existingRow[col] || "";
                    const newDescription = row[col] || "";
                    const combinedDescriptions = new Set([
                        ...existingDescription
                            .split(";")
                            .filter(Boolean)
                            .map((s: string) => s.trim()),
                        ...newDescription
                            .split(";")
                            .filter(Boolean)
                            .map((s: string) => s.trim())
                    ]);
                    existingRow[col] = Array.from(combinedDescriptions).join("; ");
                } else if (row[col]) {
                    existingRow[col]?.add(row[col]);
                }
            });
        }
    });

    return Array.from(map.values()).map((row) => {
        const resultRow: any = {};
        Object.keys(row).forEach((col) => {
            if (col === "sanctionsDescription") {
                resultRow[col] = row[col].replace(/,\s*$/, "").trim();
            } else if (col === "controlDate" || col === "entityRegulationPublicationDate" || col === "dateDesignated") {
                const dates = Array.from(row[col]);
                if (dates.length > 1) {
                    resultRow[col] = dates.join(", ").replace(/,\s,/g, ",");
                } else {
                    resultRow[col] = dates[0] + "T00:00:00";
                }
            } else {
                resultRow[col] = Array.from(row[col]).join(", ").replace(/,\s,/g, ",");
            }
        });
        return resultRow;
    });
};
export const consolidateDataUK = (data, keys) => {
    data.forEach((row: any) => {
        row.fullName = [row.name6, row.name1].filter(Boolean).join(", ");
        row.name = [row.name1, row.name2, row.name3, row.name4, row.name5, row.name6].filter(Boolean).join(";");
        if (row.dateDesignated.trim() !== "") {
            row.dateDesignated = row.dateDesignated.split("/").reverse().join("-");
        }
    });

    return consolidateSanctionsData(data, keys);
};

export const consolidateDataOFAC = (data, keys) => {
    data.forEach((row: any) => {
        row.id = row.id.trim();
    });

    return consolidateSanctionsData(data, keys);
};

export const consolidateDataASO = (data, keys) => {
    data.forEach((row: any) => {
        row.id = row.id.replace(/(?<=[0-9+])[a-z]+\s*/gi, "").trim();
    });

    return consolidateSanctionsData(data, keys);
};

export const consolidateDataEU = (data, keys) => {
    const map = new Map();

    data.forEach((row: any) => {
        // Replace dots with underscores in the 'id' field
        if (row.id) {
            row.id = row.id.replace(/\./g, "_");
        }

        const uniqueKey = row[keys];
        if (row.entityRegulationPublicationDate && row.entityRegulationPublicationDate.trim() !== "") {
            row.entityRegulationPublicationDate = row.entityRegulationPublicationDate + "T00:00:00";
        }

        if (!map.has(uniqueKey)) {
            const newRow: any = { ...row, name: "", idNumber: "" };

            // Determine the name field based on the presence of wholeName or other name fields
            if (row.wholeName) {
                newRow.name = row.wholeName;
            } else {
                const names = [row.firstName, row.middleName, row.lastName].filter(Boolean).join(" ");
                newRow.name = names || "";
            }

            // Concatenate identificationNumber and identificationLatinNumber into idNumber
            newRow.idNumber = [row.identificationNumber, row.identificationLatinNumber].filter(Boolean).join("; ");

            // Sanctions description handling
            newRow.sanctionsDescription = row.sanctionsDescription || "";

            map.set(uniqueKey, newRow);
        } else {
            const existingRow = map.get(uniqueKey);

            // Concatenate names
            if (row.wholeName) {
                existingRow.name = [existingRow.name, row.wholeName].filter(Boolean).join(", ");
            } else {
                const names = [row.firstName, row.middleName, row.lastName].filter(Boolean).join(" ");
                existingRow.name = [existingRow.name, names].filter(Boolean).join(", ");
            }

            // Concatenate idNumber
            const newIdNumbers = [row.identificationNumber, row.identificationLatinNumber].filter(Boolean).join("; ");
            if (newIdNumbers) {
                const existingIdNumbers = existingRow.idNumber.split(";").map((s: string) => s.trim());
                const newIdNumbersArray = newIdNumbers.split(";").map((s: string) => s.trim());
                const combinedIdNumbers = new Set([...existingIdNumbers, ...newIdNumbersArray]);
                existingRow.idNumber = Array.from(combinedIdNumbers).join("; ");
            }

            // Concatenate sanctionsDescription
            if (row.sanctionsDescription) {
                const existingDescriptions = existingRow.sanctionsDescription.split(",").map((s: string) => s.trim());
                const newDescriptions = row.sanctionsDescription.split(",").map((s: string) => s.trim());
                const combinedDescriptions = new Set([...existingDescriptions, ...newDescriptions]);
                existingRow.sanctionsDescription = Array.from(combinedDescriptions).join(", ");
            }
        }
    });
    const result = Array.from(map.values());

    return result;
};
