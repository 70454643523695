import { Table, TableHead, TableRow, TableCell, TableBody, Divider, Stack } from "@mui/material";
import { FirmSettings, SanctionIntegrationStatus, SanctionsLists } from "aderant-conflicts-models";
import { aderantColors, AderantTheme, Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, MessageDialog } from "@aderant/aderant-react-components";
import React, { useEffect } from "react";
import { EntitySpreadsheetImport } from "./EntitySpreadsheetImport";
import { conflictsPalette } from "styles/conflictsPalette";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Messages } from "pages/FirmSettingsPage/FirmSettingsSections/SanctionsSection/Messages";
import { RootState } from "MyTypes";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "state/actions/AdminActions";
import { getIsFirmSettingsPageFetching } from "state/selectors/adminSelectors";

type ListRowType = { displayName: string; listType: FirmSettings.SanctionsListType; lastUpdateCompleted: Date | undefined; status?: string };
//ToDo - US59617: This is the first pass at the Sanctions Import page. It will need to be updated to match the final design.
export const SanctionsTable = () => {
    const [entityName, setEntityName] = React.useState<string>("");
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [isImportBtnClicked, setImportBtnClicked] = React.useState<boolean>(false);
    const [showDeleteListDialog, setShowDeleteListDialog] = React.useState<boolean>(false);
    const [dialogBoxImportId, setDialogBoxImportId] = React.useState<string | null>(null);
    const [showDataDialog, setShowDataDialog] = React.useState<boolean>(false);
    const [currentSanctionsListType, setCurrentSanctionsListType] = React.useState<string>("");
    const [importingListTypes, setImportingListTypes] = React.useState<string[]>([]);

    const dispatch = useDispatch();
    const pageName = FirmSettings.DataImportsDefinition.name;
    const isPageFetching: boolean = useSelector((rootState: RootState) => getIsFirmSettingsPageFetching(rootState, pageName));
    const isDataImportPersisting: boolean = useSelector((rootState: RootState) => rootState.admin.isPersisting?.["data-import"] ?? false);
    const IngestionSanctionStatuses = useSelector((state: RootState) => state.admin.sanctionIntegration.ingestionSanctionStatuses);

    useEffect(() => {
        //Refetch statuses everytime we open the tab
        if (!isPageFetching) {
            dispatch(adminActions.fetchSanctionsIngestionStatus());
        }
    }, []);

    const getMessageForDialogBoxId = (id: string | null) => {
        switch (id) {
            case "delete-eu":
                setCurrentSanctionsListType("EU");
                break;
            case "delete-ofac":
                setCurrentSanctionsListType("OFAC");
                break;
            case "delete-uk":
                setCurrentSanctionsListType("UK");
                break;
            default:
                return "Invalid action";
        }
    };

    function importList(id: string | null): void {
        setImportBtnClicked(false);
        if (!id) {
            console.error("No id found on target. Import button does not have id. This is needed to determine which data to import");
            return;
        }
        if (!id.startsWith("import-")) {
            console.error("Invalid id found on target. Valid id is needed to determine which data to import. Id does not start with import-. Id: " + id);
            return;
        }
        if (SanctionsLists[id.substring(7)] !== id.substring(7)) {
            console.error("Invalid id found on target. Valid id is needed to determine which data to import. List type not found. Id: " + id);
            return;
        }

        const listType = id.substring(7);
        setEntityName(`sanctions${listType}`);
        setImportingListTypes((prev) => [...prev, listType]);
        // setEntityName(`sanctions${id.substring(7)}`);
        setIsOpen(true);
    }
    const deleteList = (id: string | null): void => {
        setShowDeleteListDialog(false);
        if (!id) {
            console.error("No id found on target. Delete button does not have id. This is needed to determine which data to delete");
            return;
        }
        if (!id.startsWith("delete-")) {
            console.error("Invalid id found on target. Valid id is needed to determine which data to delete. Id does not start with delete-. Id: " + id);
            return;
        }
        if (SanctionsLists[id.substring(7)] !== id.substring(7)) {
            console.error("Invalid id found on target. Valid id is needed to determine which data to delete. List type not found. Id: " + id);
            return;
        }
        dispatch(adminActions.deleteEntityType({ entityType: `sanctions${id.substring(7)}` }));
    };

    function getLastImportText(IngestionSanctionStatuses: SanctionIntegrationStatus[], listType: string, isDataImportPersisting: boolean) {
        const matchedRow = IngestionSanctionStatuses?.find((row: SanctionIntegrationStatus) => row?.partitionKey === "sanctions" + listType);
        if (importingListTypes.includes(listType) && isDataImportPersisting) {
            return <span style={{ fontWeight: "bold", color: "#5F7286" }}>Importing</span>;
        }

        if (!matchedRow) {
            return "";
        }

        const now = new Date();
        const timeDifference = (now.getTime() - matchedRow?.lastStatusChangeTime?.getTime()) / (1000 * 60); // in minutes

        if (matchedRow?.status === "Pending" && timeDifference > 90) {
            return "Import Failed";
        }
        if (matchedRow?.status === "Completed" && matchedRow?.lastSuccessTime) {
            return matchedRow.lastSuccessTime.toLocaleString(undefined, { hour12: false });
        }
        if (matchedRow?.status === "Pending" && timeDifference <= 90) {
            return "Processing";
        }
        if (matchedRow?.status === "Failed") {
            return "Import Failed";
        }
        return "";
    }

    function ImportTableRow(sanctionsLists: ListRowType[]) {
        return sanctionsLists.map((row: ListRowType, index: number) => {
            const matchedRow = IngestionSanctionStatuses?.find((status: SanctionIntegrationStatus) => status?.partitionKey === "sanctions" + row.listType);

            return (
                <TableRow
                    key={row.listType}
                    className="sanctions-list-row"
                    sx={{
                        fontSize: AderantTheme.typography.pxToRem(16),
                        color: aderantColors.Ahsoka,
                        borderBottom: index === sanctionsLists.length - 1 ? "none" : `1px solid rgba(229, 229, 229)`,
                        td: {
                            padding: "0.2rem 0rem"
                        }
                    }}
                >
                    <TableCell sx={{ color: "#5F7286" }}> {row.displayName}</TableCell>
                    <TableCell sx={{ color: "#5F7286" }}>{getLastImportText(IngestionSanctionStatuses, row.listType, isDataImportPersisting)}</TableCell>
                    <TableCell align="right">
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <span
                                id={`import-${row.listType}`}
                                style={{ display: "flex", alignItems: "center", cursor: isDataImportPersisting ? "not-allowed" : "pointer", color: isDataImportPersisting ? "#ccc" : "#1379CE" }}
                                onClick={(e) => {
                                    if (!isDataImportPersisting) {
                                        setDialogBoxImportId(e.currentTarget.id);
                                        setImportBtnClicked(true);
                                    }
                                }}
                            >
                                <CloudUploadOutlinedIcon style={{ marginRight: "5px", color: "#1379CE", height: "20px", width: "20px" }} />
                                Import
                            </span>
                            {matchedRow && (
                                <span
                                    id={`delete-${row.listType}`}
                                    style={{ display: "flex", alignItems: "center", cursor: isDataImportPersisting ? "not-allowed" : "pointer", color: isDataImportPersisting ? "#ccc" : "#CC0000" }}
                                    onClick={(e) => {
                                        if (!isDataImportPersisting) {
                                            getMessageForDialogBoxId(e.currentTarget.id);
                                            setDialogBoxImportId(e.currentTarget.id);
                                            setShowDeleteListDialog(true);
                                        }
                                    }}
                                >
                                    <DeleteOutlineOutlinedIcon style={{ color: "#CC0000", height: "20px", width: "20px" }} />
                                    Delete
                                </span>
                            )}
                        </Stack>
                    </TableCell>
                </TableRow>
            );
        });
    }

    const sanctionsLists: ListRowType[] = [
        {
            displayName: FirmSettings.SanctionsMessages.ASO_LIST.getMessage(),
            listType: SanctionsLists.aso,
            lastUpdateCompleted: new Date()
        },
        {
            displayName: FirmSettings.SanctionsMessages.EU_LIST.getMessage(),
            listType: SanctionsLists.eu,
            lastUpdateCompleted: new Date()
        },
        {
            displayName: FirmSettings.SanctionsMessages.OFAC_LIST.getMessage(),
            listType: SanctionsLists.ofac,
            lastUpdateCompleted: new Date()
        },
        {
            displayName: FirmSettings.SanctionsMessages.UK_LIST.getMessage(),
            listType: SanctionsLists.uk,
            lastUpdateCompleted: new Date()
        }
    ];
    return (
        <>
            <Table style={{ minWidth: 650, margin: "0 38px", width: "94%" }}>
                <TableHead sx={{ backgroundColor: conflictsPalette.background.white, color: conflictsPalette.text.black, border: 0 }}>
                    <TableRow
                        key={"sanctions-list-table-header"}
                        sx={{
                            th: {
                                fontSize: AderantTheme.typography.pxToRem(14),
                                color: "rgb(51, 51, 51)",
                                padding: "0 0rem",
                                fontWeight: 600
                            },
                            border: 0
                        }}
                    >
                        <TableCell>{FirmSettings.SanctionsMessages.SANCTIONS_LIST.getMessage()}</TableCell>
                        <TableCell>{FirmSettings.SanctionsMessages.LAST_IMPORTED.getMessage()}</TableCell>
                        <TableCell align="right" style={{ visibility: "hidden" }}>
                            {FirmSettings.SanctionsMessages.IMPORT.getMessage()}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{ImportTableRow(sanctionsLists)}</TableBody>
            </Table>
            <Dialog open={isImportBtnClicked} onClose={() => setImportBtnClicked(false)} size="sm" aria-labelledby="test">
                <DialogTitle>
                    <DialogHeader>{Messages.PROCEED_IMPORT_TITLE.getMessage()}</DialogHeader>
                </DialogTitle>
                <DialogContent style={{ whiteSpace: "pre-line" }}>
                    <>
                        <div>
                            <div style={{ width: "100%" }}>
                                <span>{Messages.PROCEED_IMPORT_PROMPT.getMessage()}</span>
                            </div>
                        </div>
                    </>
                </DialogContent>
                <DialogFooter>
                    <>
                        <Button
                            text="No"
                            color="secondary"
                            onClick={() => {
                                setImportBtnClicked(false);
                            }}
                        />
                        <Button text="Yes" color="primary" onClick={() => importList(dialogBoxImportId)} />
                    </>
                </DialogFooter>
            </Dialog>
            <MessageDialog
                open={showDeleteListDialog}
                onClose={() => setShowDeleteListDialog(false)}
                message={Messages.DELETE_LIST_PROMPT.getMessage(currentSanctionsListType)}
                title={Messages.DELETE_LIST_TITLE.getMessage()}
                footer={
                    <>
                        <Button
                            text="No"
                            color="secondary"
                            onClick={() => {
                                setShowDeleteListDialog(false);
                            }}
                        />
                        <Button text="Yes" color="primary" onClick={() => deleteList(dialogBoxImportId)} />
                    </>
                }
            />

            {isOpen && <EntitySpreadsheetImport entityName={entityName} isOpen={isOpen} setIsOpen={setIsOpen} showDataDialog={showDataDialog} setShowDataDialog={setShowDataDialog} />}

            {showDataDialog && (
                <MessageDialog
                    open={showDataDialog}
                    onClose={() => setShowDataDialog && setShowDataDialog(false)}
                    message={Messages.SANCTIONS_DATA_LIMIT_MESSAGE.getMessage()}
                    title={Messages.SANCTIONS_DATA_LIMIT_TITLE.getMessage()}
                    footer={<Button text="Close" color="primary" onClick={() => setShowDataDialog && setShowDataDialog(false)} />}
                />
            )}
        </>
    );
};
