import { EditableNumberFieldDefinition } from "aderant-conflicts-models";
import React from "react";
import { FieldProps } from "./Types/FieldProps";
import { PickList } from "@aderant/aderant-react-components";

type IntegerFieldProps = {
    field: EditableNumberFieldDefinition;
} & FieldProps;

export function IntegerField(props: IntegerFieldProps): JSX.Element {
    const min = props.field.min ?? 0;
    const max = props.field.max ?? 100;

    const labelValue = {
        label: (props.data ?? props.field.defaultValue) + " days",
        value: (props.data ?? props.field.defaultValue).toString()
    };

    const pickListOptions = Array.from({ length: max - min + 1 }, (_, i) => i + min).map((value) => ({
        label: value + " days",
        value: value.toString()
    }));

    return (
        <PickList
            key={props.field.name}
            id={`${props.field.name}-options-dropdown`}
            label={props.field.label}
            labelProps={{ width: "300px", position: "left" }}
            style={{ width: "500px" }}
            value={{ label: labelValue.label, value: labelValue.value }}
            onChange={(e, labelValuePair) => {
                props.onChange(parseInt(labelValuePair?.value ?? props.field.defaultValue));
            }}
            selectOptions={pickListOptions}
            disabled={props.isPagePersisting}
        />
    );
}
