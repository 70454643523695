import { Message } from "aderant-conflicts-models";

export const Messages = {
    //Sanction Modal messages
    PROCEED_IMPORT_PROMPT: new Message(
        "PROCEED_IMPORT_PROMPT",
        "Importing data changes entities, which may affect search requests that are being created or in progress. We recommend importing data outside of regular operating hours. \r\n\r\n If importing new data when data already exists, the existing data will be deleted and replaced. \r\n\r\n Do you wish to continue?"
    ),
    PROCEED_IMPORT_TITLE: new Message("PROCEED_IMPORT_TITLE", "Proceed with Import?"),
    DELETE_LIST_TITLE: new Message("DELETE_LIST_TITLE", "Delete Sanctions Data?"),
    DELETE_LIST_PROMPT: new Message<[entityType: string]>("DELETE_LIST_PROMPT", "Are you sure you want to delete Sanctions {0} data?"),
    SANCTIONS_DATA_LIMIT_TITLE: new Message("SANCTIONS_DATA_LIMIT_TITLE", "Import Failure"),
    SANCTIONS_DATA_LIMIT_MESSAGE: new Message("SANCTIONS_DATA_LIMIT_MESSAGE", "The imported spreadsheet contains too many rows. Remove some rows and try again.")
};
