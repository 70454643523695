import { IDENTITY_CONFLICTS_PRODUCTION_SUFFIX, LogContext, LoggedInUser, User, getSubscriptionName, getSubscriptionResourceNameSuffix } from "aderant-conflicts-models";
import _ from "lodash";
import { isConflictsRole, rolesArrayToUserRole } from "../../OAuth/roles";
import { UserResponse } from "../APIs";
import * as APIs from "../APIs/index";
import { AzureFunctionProxy } from "../AzureFunctionDefinition";

export class GlobalUserService {
    private static UsersCacheLastUpdated: Record<string, Record<string, number>> = {}; //Object where property names are tenancy id, and property values are Datetime the UsersCache was last updated for that tenancy.
    private static UsersCache: Record<string, Record<string, LoggedInUser[]>> = {}; //Object where property names are tenancy id, and property values are an array of users in the tenancy
    private static UserCache: Record<string, Record<string, Record<string, LoggedInUser>>> = {}; //Object where property names are tenancy id, and property values are an object whose property names are the userid and that property value is the user
    private userManagement: APIs.UserManagementProxy;
    private static cacheAge = 5 * 60 * 1000; //Default cache age = 5 minutes
    constructor(private context: LogContext, userManagement: { getUsers: AzureFunctionProxy<{ tenancyId: string; tenancyUniqueName: string; subscriptionId: string }, UserResponse[], never> }) {
        this.userManagement = userManagement;
    }

    async initializeUserCache(tenancy: { tenancyId: string; uniqueName: string; subscriptionId: string }): Promise<User[]> {
        const subscriptionName = getSubscriptionName(tenancy.uniqueName, tenancy.subscriptionId, "GlobalUserService.initializeUserCache");

        this.context.logger.info(`GlobalUserService initializeUserCache: fetching users for tenancy id ${tenancy.tenancyId} and subscription id ${tenancy.subscriptionId}.`);

        const users = await this.userManagement.getUsers({ tenancyId: tenancy.tenancyId, subscriptionId: tenancy.subscriptionId, tenancyUniqueName: tenancy.uniqueName });

        if (users.length > 0) {
            this.context.logger.debug(
                `GlobalUserService initializeUserCache: ${users.length} users fetched from User Management for tenancy id ${tenancy.tenancyId} and subscription id ${tenancy.subscriptionId}.`
            );
        } else {
            this.context.logger.warn(
                `GlobalUserService initializeUserCache: Didn't find any users when fetching user list for tenancy id ${tenancy.tenancyId} and subscription id ${tenancy.subscriptionId} from user management.`
            );
        }

        let cachedUsers: LoggedInUser[] = [];
        if (subscriptionName === IDENTITY_CONFLICTS_PRODUCTION_SUFFIX) {
            this.context.logger.info(`GlobalUserService initializeUserCache: getting roles for tenancy id ${tenancy.tenancyId}, ${tenancy.subscriptionId} subscription.`);
            cachedUsers = _.sortBy(users, (u: UserResponse) => u.displayName)
                .filter((u: UserResponse) => u.userApplications?.find((a) => a.applicationName.toLowerCase().startsWith("conflicts")))
                .map((u: UserResponse) => {
                    const roles = new Set(u.userApplications.flatMap((application) => (application.roles ?? []).map((role) => role.value).filter(isConflictsRole)));

                    return {
                        id: u.id,
                        name: u.displayName,
                        email: u.alternateEmail ?? u.userEmail, //prioritize alternateEmail - it will be their actual home AD/idp email if they have one
                        role: rolesArrayToUserRole([...roles]),
                        tenancy: {
                            id: tenancy.tenancyId,
                            uniqueName: tenancy.uniqueName,
                            subscription: { id: tenancy.subscriptionId, resourceNameSuffix: "", displayName: IDENTITY_CONFLICTS_PRODUCTION_SUFFIX }
                        }
                    };
                })
                .filter((u) => u.role !== 0);
        } else {
            this.context.logger.info(`GlobalUserService initializeUserCache: getting roles for tenancy id ${tenancy.tenancyId}, ${tenancy.subscriptionId} subscription.`);
            cachedUsers = _.sortBy(users, (u: UserResponse) => u.displayName)
                .filter((u: UserResponse) => u.userApplications?.find((a) => a.applicationName.toLowerCase().startsWith("conflicts")))
                .map((u: UserResponse) => {
                    const roles = new Set(
                        u.userApplications.flatMap((application) =>
                            (application.environments ?? [])
                                .filter((environment) => environment.environmentName === subscriptionName)
                                .flatMap((environment) => (environment.roles ?? []).map((role) => role.value).filter(isConflictsRole))
                        )
                    );
                    return {
                        id: u.id,
                        name: u.displayName,
                        email: u.alternateEmail ?? u.userEmail, //prioritize alternateEmail - it will be their actual home AD/idp email if they have one
                        role: rolesArrayToUserRole([...roles]),
                        tenancy: {
                            id: tenancy.tenancyId,
                            uniqueName: tenancy.uniqueName,
                            subscription: {
                                id: tenancy.subscriptionId,
                                resourceNameSuffix: getSubscriptionResourceNameSuffix(tenancy.uniqueName, tenancy.subscriptionId, "GlobalUserService.initializeUserCache"),
                                displayName: subscriptionName
                            }
                        }
                    };
                });
        }

        if (!GlobalUserService.UsersCache[tenancy.tenancyId]) {
            GlobalUserService.UsersCache[tenancy.tenancyId] = { [tenancy.subscriptionId]: cachedUsers };
        } else {
            GlobalUserService.UsersCache[tenancy.tenancyId] = { ...GlobalUserService.UsersCache[tenancy.tenancyId], [tenancy.subscriptionId]: cachedUsers };
        }

        if (!GlobalUserService.UsersCacheLastUpdated[tenancy.tenancyId]) {
            GlobalUserService.UsersCacheLastUpdated[tenancy.tenancyId] = { [tenancy.subscriptionId]: Date.now() };
        } else {
            GlobalUserService.UsersCacheLastUpdated[tenancy.tenancyId] = {
                ...GlobalUserService.UsersCacheLastUpdated[tenancy.tenancyId],
                [tenancy.subscriptionId]: Date.now()
            };
        }
        GlobalUserService.UserCache[tenancy.tenancyId] = { ...GlobalUserService.UserCache[tenancy.tenancyId], [tenancy.subscriptionId]: {} };
        cachedUsers.map((user: LoggedInUser) => {
            GlobalUserService.UserCache[tenancy.tenancyId][tenancy.subscriptionId] = {
                ...GlobalUserService.UserCache[tenancy.tenancyId][tenancy.subscriptionId],
                [user.id]: user
            };
        });
        return cachedUsers;
    }

    async getUsers(tenancy: { tenancyId: string; uniqueName: string; subscriptionId: string }, cacheAge?: number): Promise<LoggedInUser[]> {
        this.context.logger.info(`GlobalUserService: getUsers for tenancy id ${tenancy.tenancyId}, subscription id ${tenancy.subscriptionId}`);

        if (
            !GlobalUserService.UsersCache[tenancy.tenancyId] ||
            !GlobalUserService.UsersCache[tenancy.tenancyId][tenancy.subscriptionId] ||
            !GlobalUserService.UsersCacheLastUpdated[tenancy.tenancyId] ||
            !GlobalUserService.UsersCacheLastUpdated[tenancy.tenancyId][tenancy.subscriptionId] ||
            (GlobalUserService.UsersCacheLastUpdated[tenancy.tenancyId][tenancy.subscriptionId] &&
                Date.now() - GlobalUserService.UsersCacheLastUpdated[tenancy.tenancyId][tenancy.subscriptionId] > (cacheAge ?? GlobalUserService.cacheAge))
        ) {
            this.context.logger.debug(`GlobalUserService:getUsers: refreshing users cache for tenancy id ${tenancy.tenancyId}, subscription id ${tenancy.subscriptionId}.`);
            await this.initializeUserCache(tenancy);
        }
        if (GlobalUserService.UsersCache[tenancy.tenancyId][tenancy.subscriptionId]) {
            this.context.logger.debug(
                `GlobalUserService:getUsers: returning ${GlobalUserService.UsersCache[tenancy.tenancyId][tenancy.subscriptionId].length} users from cache for tenancy id ${
                    tenancy.tenancyId
                }, subscription id ${tenancy.subscriptionId}.`
            );
            return GlobalUserService.UsersCache[tenancy.tenancyId][tenancy.subscriptionId];
        }
        this.context.logger.warn(`GlobalUserService: getUsers: No users found for tenancy id ${tenancy.tenancyId}, subscription id ${tenancy.subscriptionId}.`);
        return [];
    }

    async getUser(id: string, tenancy: { tenancyId: string; uniqueName: string; subscriptionId: string }): Promise<LoggedInUser | null> {
        this.context.logger.info(`Fetching user for user id ${id}, tenancy id ${tenancy.tenancyId}, subscription id ${tenancy.subscriptionId}.`);
        if (
            !GlobalUserService.UserCache[tenancy.tenancyId] ||
            !GlobalUserService.UserCache[tenancy.tenancyId][tenancy.subscriptionId] ||
            !GlobalUserService.UserCache[tenancy.tenancyId][tenancy.subscriptionId][id]
        ) {
            this.context.logger.debug(`GlobalUserService:getUser: refreshing user cache for tenancy id ${tenancy.tenancyId}, subscription id ${tenancy.subscriptionId}.`);
            await this.initializeUserCache(tenancy);
        }
        if (GlobalUserService.UserCache[tenancy.tenancyId][tenancy.subscriptionId] && GlobalUserService.UserCache[tenancy.tenancyId][tenancy.subscriptionId][id]) {
            this.context.logger.debug(
                `GlobalUserService:getUser: returning user ${JSON.stringify(GlobalUserService.UserCache[tenancy.tenancyId][tenancy.subscriptionId][id], null, 2)} from cache for tenancy id ${
                    tenancy.tenancyId
                }, subscription id ${tenancy.subscriptionId} and user id ${id}.`
            );
            return GlobalUserService.UserCache[tenancy.tenancyId][tenancy.subscriptionId] && GlobalUserService.UserCache[tenancy.tenancyId][tenancy.subscriptionId][id];
        }
        this.context.logger.warn(`GlobalUserService: getUser: User for user id ${id} and tenancy id ${tenancy.tenancyId}, subscription id ${tenancy.subscriptionId} not found.`);
        return null;
    }
}
