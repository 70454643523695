import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ConflictsAction } from "aderant-conflicts-models";
import { Button } from "@aderant/aderant-react-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { refinerActions } from "state/actions/RefinerActions";
import { VIEW_BY_FILTERS, ViewByRefiner } from "state/reducers/ReducerStateTypes";
import { getGridPreferences, usePermissionsContext } from "state/selectors";
import { conflictsPalette } from "styles/conflictsPalette";
import ViewByCheckboxes, { numberOfRefinersSelected } from "./ViewByCheckboxes";
import ViewByPickLists from "./ViewByPickLists";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { gridPreferencesEqualityFunction } from "./DataGridWithRefiner";

type ViewByRefinerContainerProps = { gridId: string; currentUserId: string; disabled: boolean; persistViewByRefiners: boolean | undefined };

const ViewByRefinerContainer = (props: ViewByRefinerContainerProps): JSX.Element | null => {
    const { gridId, currentUserId, disabled, persistViewByRefiners } = props;
    const [isRefinerOpen, setIsRefinerOpen] = useState(true);
    const dispatch = useDispatch();
    const permissions = usePermissionsContext();

    const canViewOwnSearches = permissions.currentUserHasPermission(ConflictsAction.ViewOwnSearchesAndAllHits);
    const canViewOthersSearches = permissions.currentUserHasPermission(ConflictsAction.ViewOthersSearchesAndAllHits);
    const featureFlags = useFeatureFlags();
    const gridPreferences = useSelector(getGridPreferences(gridId), gridPreferencesEqualityFunction);
    useEffect(() => {
        const viewByRefiners: ViewByRefiner[] = [];
        if (featureFlags?.stickyRefiners) {
            //load viewByRefiners from grid preferences
            if (canViewOthersSearches) {
                VIEW_BY_FILTERS.forEach((obj) => {
                    const ids = gridPreferences?.viewByPreferences?.find((v) => v.path === obj.path)?.ids;
                    // When viewByPreferences are saved to gridPreferences in local storage, JSON.stringify replaces undefined values in arrays, with null - this results in an array of [null, null], instead of an array on [null, undefined]
                    // When the loading the viewByPreferences from local storage, we need to convert [null, null] back to [null, undefined] to ensure an option of Unassigned will select rows that have both null and undefined values
                    // If the ids array contains a null but not an undefined, add an undefined to the array - unassigned option means an id of either null or undefined
                    const nullIndex = ids?.indexOf(null) ?? -1;
                    if (nullIndex !== -1) {
                        const undefinedIndex = ids?.indexOf(undefined) ?? -1;
                        if (undefinedIndex == -1) {
                            ids?.push(undefined);
                        }
                        //check if there is a second null in the array and remove it
                        const secondNullIndex = ids?.indexOf(null, nullIndex + 1) ?? -1;
                        if (secondNullIndex !== -1) {
                            ids?.splice(secondNullIndex, 1);
                        }
                    }
                    viewByRefiners.push({ name: obj.name, path: obj.path, ids: ids ?? [currentUserId] });
                });
            } else {
                //can only view own searches so this user gets checkboxes and must have at least 1 selected
                const selectedCount = numberOfRefinersSelected(gridPreferences?.viewByPreferences);
                if (selectedCount === 0) {
                    //If no checkboxes are selected, select all
                    VIEW_BY_FILTERS.forEach((obj) => viewByRefiners.push({ name: obj.name, path: obj.path, ids: [currentUserId] }));
                } else {
                    VIEW_BY_FILTERS.forEach((obj) => {
                        const ids = gridPreferences?.viewByPreferences?.find((v) => v.path === obj.path)?.ids;
                        if (ids?.length === 0) {
                            //No user selected - this equates to a cleared checkbox
                            viewByRefiners.push({ name: obj.name, path: obj.path, ids: [] });
                        } else {
                            //current user is selected by default if they had no grid preferences or the grid preferences were for their own or others' searches
                            viewByRefiners.push({ name: obj.name, path: obj.path, ids: [currentUserId] });
                        }
                    });
                }
            }
        } else {
            VIEW_BY_FILTERS.forEach((obj) => viewByRefiners.push({ name: obj.name, path: obj.path, ids: [currentUserId] }));
        }
        dispatch(refinerActions.setViewByRefiners(viewByRefiners));
    }, []);

    return canViewOwnSearches || canViewOthersSearches ? (
        <div
            data-testid={"viewBy-refiner"}
            style={{ padding: "5px 15px 10px 2px", backgroundColor: conflictsPalette.background.white, borderBottom: `1px solid ${conflictsPalette.border}`, opacity: disabled ? 0.4 : 1 }}
        >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <h2 style={{ fontSize: "18px", color: conflictsPalette.text.black, fontWeight: 400, margin: "10px 15px" }}>View By</h2>
                <Button
                    iconButton
                    startIcon={isRefinerOpen ? <ExpandLess /> : <ExpandMore />}
                    onClick={() => setIsRefinerOpen(!isRefinerOpen)}
                    aria-label="Expand less viewBy"
                    variant="plain"
                    rounded
                    disabled={disabled}
                />
            </div>
            {isRefinerOpen ? (
                canViewOthersSearches ? (
                    <ViewByPickLists gridId={gridId} disabled={disabled} persistViewByRefiners={persistViewByRefiners} />
                ) : canViewOwnSearches ? (
                    <ViewByCheckboxes gridId={gridId} currentUserId={currentUserId} disabled={disabled} persistViewByRefiners={persistViewByRefiners} />
                ) : null
            ) : null}
        </div>
    ) : null;
};

export default ViewByRefinerContainer;
